import { useObjectVal } from "react-firebase-hooks/database"
import firebase from "gatsby-plugin-firebase"

const useDynamicRating = workoutID => {
  const workoutPath = `workouts/${workoutID}`
  const workoutRef = firebase.database().ref(workoutPath)

  const [workout, loading, error] = useObjectVal(workoutRef)

  // Extract the rating from the workout data
  const rating = workout ? workout.rating : null

  return [rating, loading, error]
}

export default useDynamicRating
