import React from "react"
import { useAuth0 } from "@utils/auth"

const CompletedIcon = ({ id }) => {
  const { completedWorkouts } = useAuth0()

  const checkIsFavourite = completedWorkouts.includes(id)

  return (
    <div>
      {checkIsFavourite && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 46">
          <defs>
            <filter
              id="Path_2"
              width="46"
              height="46"
              x="0"
              y="0"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dy="1" />
              <feGaussianBlur result="blur" stdDeviation="2" />
              <feFlood flood-opacity=".2" />
              <feComposite in2="blur" operator="in" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g
            id="complete_workout"
            data-name="complete workout"
            transform="translate(6 5)"
          >
            <g filter="url(#Path_2)" transform="translate(-6 -5)">
              <path
                id="Path_2-2"
                fill="#71ccac"
                d="M17 0a17 17 0 0 1 9.456 31.129A16.691 16.691 0 0 1 17 34a17 17 0 0 1 0-34Z"
                data-name="Path 2"
                transform="translate(6 5)"
              />
            </g>
            <path
              id="Path_1"
              fill="#fff"
              d="M-6080.138-8232.51a1.5 1.5 0 0 1-1.281-.72l-3.649-6a1.5 1.5 0 0 1 .5-2.062 1.5 1.5 0 0 1 2.061.5l2.138 3.52c.348-.715.776-1.533 1.291-2.415a31.9 31.9 0 0 1 8.2-9.427 1.5 1.5 0 0 1 2.1.307 1.5 1.5 0 0 1-.307 2.1 28.854 28.854 0 0 0-7.362 8.467 29.44 29.44 0 0 0-2.272 4.72 1.5 1.5 0 0 1-1.258 1 1.5 1.5 0 0 1-.161.01Z"
              data-name="Path 1"
              transform="translate(6094.286 8258.412)"
            />
          </g>
        </svg>
      )}
    </div>
  )
}

export default CompletedIcon
