import React from "react"
import style from "./cardsContainer.mod.scss"
import Button from "@elements/Button"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

const Cards = ({
  children = [],
  heading,
  lead,
  buttonPath,
  buttonText,
  modifier,
  orientation,
  columns,
  size,
  desktopScroll = false,
  dateStart,
  dateEnd
}) => {
  const classes = [style.cards]
  modifier && classes.push(`${style.cards}--${modifier}`)
  desktopScroll && classes.push(`${style.cards}--desktop-scroll`)
  orientation && classes.push(`${style.cards}--${orientation}`)
  orientation &&
    columns &&
    classes.push(`${style.cards}--${orientation}-${columns}-col`)
  orientation &&
    columns &&
    size &&
    classes.push(`${style.cards}--${orientation}-${columns}-col-for-${size}`)
  columns && size && classes.push(`${style.cards}--${columns}-col-for-${size}`)

  return (
    <div className={classes.join(" ")}>
      {heading && (
        <div className={style.cards__header}>
          <h2 className={style.cards__heading}>{heading}</h2>
          {buttonPath && buttonText && (
            <div className={style.cards__button}>
              <Button to={buttonPath} text={buttonText} size="small" />
            </div>
          )}
          {lead && <div className={style.cards__lead}>{<p>{lead}</p>}</div>}
          {dateStart && (
            <div className={style.cards__date}>
              <p>
                {dateStart} - {dateEnd}
              </p>
            </div>
          )}
        </div>
      )}

      {children.length > 0 && (
        <div className={style.cards__inner}>
          <ul className={style.cards__list}>
            {children.map((child, index) => {
              const { id, overlay } = child.props
              return (
                <li
                  key={`list-item-${id}-${index}`}
                  className={style.cards__item}
                >
                  {child}
                  {overlay && (
                    <div className={style.cards__overlay}>{overlay}</div>
                  )}
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default Cards

Cards.propTypes = {
  children: PropTypes.array.isRequired,
  heading: PropTypes.string,
  lead: PropTypes.string,
  buttonPath: PropTypes.string,
  buttonText: PropTypes.string,
  modifier: PropTypes.string,
  orientation: PropTypes.string,
  columns: PropTypes.string,
  size: PropTypes.string
}

export const query = graphql`
  fragment WorkoutCards on DatoCmsWorkoutCategory {
    id
    originalId
    title
    shortDescription
    path
    slug
  }
`
