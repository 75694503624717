import React from "react"
import style from "./banner.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Modal from "@elements/Modal"

const Banner = ({
  image,
  hiddenLarge = false,
  hiddenSmall = false,
  link,
  video,
  overlayText = "",
  overlayHeading = ""
}) => {
  const classes = [style.banner]
  hiddenLarge && classes.push(`${style.banner}--hidden-large`)
  hiddenSmall && classes.push(`${style.banner}--hidden-small`)
  if (overlayText || overlayHeading) classes.push(`${style.banner}--overlay`)

  return (
    <div className={classes.join(" ")}>
      {link ? (
        <Link title={link.title} to={link.path}>
          <GatsbyImage
            className={style.banner__image}
            image={image.gatsbyImageData}
          />
        </Link>
      ) : (
        <GatsbyImage
          className={style.banner__image}
          image={image.gatsbyImageData}
        />
      )}
      {video && <Modal video={video} placement="center" />}
      {(overlayText || overlayHeading) && (
        <div className={style.banner__overlay}>
          <div className={style.banner__content}>
            {overlayText && <p>{overlayText}</p>}
            {overlayHeading && <h3>{overlayHeading}</h3>}
          </div>
        </div>
      )}
    </div>
  )
}

Banner.propTypes = {
  image: PropTypes.object.isRequired,
  hiddenLarge: PropTypes.bool,
  hiddenSmall: PropTypes.bool
}

export default Banner
