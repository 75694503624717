import React from "react"
import { graphql } from "gatsby"
import style from "./content.mod.scss"
import PropTypes from "prop-types"

const Content = ({
  html,
  type = "",
  textCenter = false,
  customOrderedList = "",
  styledTable = false
}) => {
  const modifiers = []
  type && modifiers.push(`${style.content}--${type}`)
  customOrderedList &&
    modifiers.push(`${style.content}--custom-ordered-list-${customOrderedList}`)
  textCenter && modifiers.push(`${style.content}--text-center`)
  styledTable && modifiers.push(`${style.content}--styled-table`)

  return (
    <div className={[style.content, ...modifiers].join(" ")}>
      <div className={style.content__body}>
        <div
          className={style.content__caption}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </div>
  )
}

export default Content

Content.propTypes = {
  html: PropTypes.string.isRequired
}

export const query = graphql`
  fragment Content on DatoCmsContent {
    id
    contentNode {
      childMarkdownRemark {
        html
      }
    }
    model {
      apiKey
    }
  }
`
