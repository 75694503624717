import { format, addWeeks, addDays } from "date-fns"

const formatDate = (timeStamp, configuration = "d MMMM yyyy") =>
  format(new Date(timeStamp), configuration)

const formatTime = (timeStamp, configuration = "h:mma") =>
  format(new Date(timeStamp), configuration)

const formatChallengeDate = (date, configuration = "EEEE do  MMMM") =>
  format(new Date(date), configuration)

const formatDateTime = (timeStamp, configuration = "d/M/yyyy") => {
  const newDate = new Date(timeStamp)
  const date = format(newDate, configuration)
  const time = format(newDate, "h:mma")

  return `${date} at ${time}`
}

const getChallengeEndDate = (startDate, duration) =>
  addWeeks(new Date(startDate), duration)

export {
  formatDate,
  formatTime,
  formatChallengeDate,
  getChallengeEndDate,
  formatDateTime
}
