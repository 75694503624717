import { getImageFromMedia } from "@helpers/workoutHelper"
import { formatDate } from "@helpers/dateHelper"

const mappedMealType = (mealType, isFav, toggleFav) => {
  return mealType.map(meal => ({
    id: meal.id,
    path: meal.path,
    title: meal.title,
    image: meal.heroImage,
    isFav: isFav,
    toggleFav: toggleFav,
    link: {
      title: meal.title,
      path: meal.path
    },
    icons: [
      {
        type: "prep",
        content: meal.prepTime,
        subText: "prep"
      },
      {
        type: "cook",
        content: meal.cookTime,
        subText: "cook"
      }
    ],
    badges: meal.dietaryTypes.map(item => ({
      svg: item.badge.url,
      label: item.name
    }))
  }))
}

const formatWorkoutFavs = (workouts, allWorkouts, toggleFav, isFav) => {
  console.info("formatWorkoutFavs method is now depricated")
  const favWorkoutIds = Array.from(workouts.keys())
  const favWorkouts = allWorkouts.filter(workout => {
    return favWorkoutIds.indexOf(workout.id) !== -1
  })

  const mappedWorkouts = favWorkouts.map(workout => ({
    id: workout.id,
    link: { title: workout.title, path: workout.path },
    title: workout.title,
    subHeading: workout.category.title,
    image: getImageFromMedia(workout.media[0]),
    isFav: isFav,
    toggleFav: toggleFav,
    equipment: workout.content[0].items,
    icons: [
      {
        type: "cook",
        content: `${workout.duration} min`
      },
      {
        type: "drop",
        content: workout.defaultRating
      }
    ]
  }))

  return mappedWorkouts
}

const transformWorkout = node => {
  const iconList = []
  if (node.duration)
    iconList.push({ type: "cook", content: `${node.duration} mins` })

  if (node.showSweatometer) {
    let rating = node.defaultRating
    if (node.sweatometerCount > 0) {
      rating = node.sweatometerRating
    }
    iconList.push({ type: "drop", content: rating })
  }

  const equipmentContent = node.content.find(block => block?.items || false)

  return {
    id: node.id,
    originalId: node.originalId,
    link: { title: node.title, path: node.path },
    title: node.title,
    image: {
      ...getImageFromMedia(node.media[0]),
      aspectRatio: 740 / 532
    },
    subHeading: node.category && node.category.title,
    equipment: equipmentContent ? equipmentContent.items : null,
    icons: iconList
  }
}

const formatRecipeFavs = (recipes, allRecipes, toggleFav, isFav) => {
  console.info("formatRecipeFavs method is now depricated")

  const favRecipeIds = Array.from(recipes.keys())
  const favRecipes = allRecipes.filter(recipe => {
    return favRecipeIds.indexOf(recipe.id) !== -1
  })

  const mappedRecipes = favRecipes.map(recipe => ({
    id: recipe.id,
    title: recipe.title,
    image: recipe.heroImage,
    isFav: isFav,
    toggleFav: toggleFav,
    link: {
      title: recipe.title,
      path: recipe.path
    },
    icons: [
      {
        type: "prep",
        content: `${recipe.prepTime} min`,
        subText: "prep"
      },
      {
        type: "cook",
        content: `${recipe.cookTime} min`,
        subText: "cook"
      }
    ],
    badges: recipe.dietaryTypes.map(item => ({
      svg: item.badge.url,
      label: item.name
    }))
  }))

  return mappedRecipes
}

const transformRecipe = node => {
  return {
    id: node.id,
    link: { title: node.title, path: node.path },
    title: node.title,
    path: node.path,
    image: node.heroImage && node.heroImage,
    icons: [
      {
        type: "prep",
        content: node.prepTime,
        subText: "prep"
      },
      {
        type: "cook",
        content: node.cookTime,
        subText: "cook"
      }
    ]
  }
}

const formatVlogFavs = (vlogs, allVlogs, toggleFav, isFav) => {
  console.info("formatVlogFavs method is now depricated")

  const favVlogIds = Array.from(vlogs.keys())
  const favVlogs = allVlogs.filter(vlog => {
    return favVlogIds.indexOf(vlog.id) !== -1
  })

  const mappedVlogs = favVlogs.map(vlog => ({
    id: vlog.id,
    link: { title: vlog.title, path: vlog.path },
    title: vlog.title,
    //TODO: add back in once favs are working
    // image: vlog.url.thumbnailUrl,
    isFav: isFav,
    toggleFav: toggleFav,
    subHeading: formatDate(vlog.meta.publishedAt)
  }))

  return mappedVlogs
}

const transformVlog = node => {
  const vlogImage = () => {
    return node.chosenThumbnail
  }

  return {
    id: node.id,
    link: { title: node.title, path: node.path },
    title: node.title,
    image: vlogImage(node),
    subHeading:
      node.subHeading ||
      (node.meta && node.meta.publishedAt && formatDate(node.meta.publishedAt))
  }
}

const transformFavouriteNode = (item, type) => {
  if (!item) return false

  try {
    switch (type) {
      case "recipes":
        return transformRecipe(item)
      case "vlogs":
        return transformVlog(item)
      default:
        return transformWorkout(item)
    }
  } catch (e) {
    console.error(e)
  }
}

export {
  mappedMealType,
  formatWorkoutFavs,
  formatRecipeFavs,
  formatVlogFavs,
  transformWorkout,
  transformRecipe,
  transformVlog,
  transformFavouriteNode
}
