import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { useLocation } from "@reach/router"

const Seo = ({ title, description, image, type = "article" }) => {
  const location = useLocation()
  const data = useStaticQuery(graphql`
    {
      datoCmsSite {
        ...GlobalSeo
      }
    }
  `)
  const { siteName, titleSuffix, fallbackSeo } = data.datoCmsSite.globalSeo
  const pageTitle = `${title || fallbackSeo.title} ${titleSuffix}`
  const metaDescription = description || fallbackSeo.description
  const metaImage = image || fallbackSeo.image.url

  return (
    <HelmetDatoCms defer={false} title={pageTitle}>
      <meta property="og:title" content={pageTitle} />
      <meta name="description" content={metaDescription} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:locale" content="en_EN" />
      <meta property="og:type" content={type} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:url" content={location.href} />
      <meta property="og:image" content={metaImage} />
    </HelmetDatoCms>
  )
}

export default Seo

export const query = graphql`
  fragment GlobalSeo on DatoCmsSite {
    globalSeo {
      siteName
      titleSuffix
      twitterAccount
      facebookPageUrl
      fallbackSeo {
        description
        title
        image {
          url(imgixParams: { w: "1000", fit: "max", fm: "jpg" })
        }
      }
    }
  }
`
