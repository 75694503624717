const getWorkoutImageSrc = workout => {
  console.log("getWorkoutImageSrc method is depricated")
  if (
    (workout.media[0].model.apiKey === "workout_video" ||
      workout.media[0].model.apiKey === "workout_video_native") &&
    workout.media[0].chosenThumbnail
  ) {
    return workout.media[0].chosenThumbnail
  } else if (
    workout.media[0].__typename === "DatoCmsWorkoutVideo" &&
    workout.media[0].chosenThumbnail === undefined
  ) {
    return workout.media[0].thumbnailUrl
  } else {
    return workout.media[0].banner
  }
}

const getImageFromMedia = media => {
  if (media === undefined || media === null) {
    console.log(`No media found`)
    return null
  }
  // image types from workout module area
  const { chosenThumbnail, banner, model } = media

  // Switch on module type
  switch (model.apiKey) {
    case "workout_video":
    case "workout_video_native":
      if (chosenThumbnail) return chosenThumbnail

      console.log(`No image found on video content`)
      break

    case "workout_audio":
      if (!banner || banner === undefined) return null
      return banner
    default:
      console.log(`type ${model.apiKey} not supported`)
      break
  }

  return null
}

export { getWorkoutImageSrc, getImageFromMedia }
