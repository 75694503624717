import React from "react"
import style from "./card.mod.scss"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Icon from "@elements/Icon"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"
import Badges from "@elements/Badges"
import EquipmentList from "../../workout/EquipmentList"
import CompletedIcon from "./CompletedIcon"
import useDynamicRating from "../../../../hook/useDynamicRating"
import { roundRating } from "../../../helpers/sweatometerHelper"

const Card = ({
  title,
  subHeading,
  image,
  link,
  modifier,
  equipment,
  icons,
  badges,
  elevated = false,
  backLink,
  originalId,
  id
}) => {
  const classes = [style.card]
  const location = useLocation()
  icons && classes.push(`${style.card}--icons`)
  modifier && classes.push(`${style.card}--${modifier}`)
  elevated && classes.push(`${style.card}--elevated`)

  const linkConfig = {
    title: link.title,
    to: link.path,
    className: classes.join(" "),
    state: {
      backLink,
      referrer: location.pathname
    }
  }

  const [dynamicRating, loading, error] = useDynamicRating(id)
  const updatedIcons = icons?.map(icon => {
    if (icon.type === "drop" && (dynamicRating || loading)) {
      return { ...icon, content: roundRating(dynamicRating?.toFixed(1)) }
    }
    return icon
  })

  return (
    <CardLink linkConfig={linkConfig}>
      <div className={style.card__inner}>
        <div className={style.card__wrapper}>
          {/* {image && <CardImage image={image} />} */}
          {image && (
            <GatsbyImage
              className={style.card__image}
              image={image.gatsbyImageData}
            />
          )}
          {equipment && <EquipmentList items={equipment} modifier="card" />}
          {badges && (
            <Badges badgeList={badges} modifiers={["dk--badges--card"]} />
          )}
          {originalId && (
            <div className={style.card__tick}>
              <CompletedIcon id={originalId} />
            </div>
          )}
        </div>
        <div className={style.card__content}>
          <h3 className={style.card__title}>{title}</h3>
          {subHeading && (
            <h4 className={style.card__subHeading}>{subHeading}</h4>
          )}
          {icons && (
            <div className={style.card__icons}>
              {updatedIcons.map(icon => {
                return (
                  <Icon
                    type={icon.type}
                    content={icon.content}
                    subText={icon.subText}
                    key={`icon-${icon.type}`}
                  />
                )
              })}
            </div>
          )}
        </div>
      </div>
    </CardLink>
  )
}

// const CardImage = ({ image }) => {
//   const config = {
//     image: image.gatsbyImageData,
//     className: style.card__image
//   }

//   return <GatsbyImage {...config} />
// }

const CardLink = ({ linkConfig, children }) => {
  const isExternal = linkConfig?.to.indexOf("http") > -1 || false

  return isExternal ? (
    <a
      className={linkConfig.className}
      title={linkConfig.title}
      href={linkConfig.to}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  ) : (
    <Link {...linkConfig}>{children}</Link>
  )
}

export default Card

Card.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  link: PropTypes.shape({
    title: PropTypes.string,
    path: PropTypes.string
  }).isRequired,
  backLink: PropTypes.shape({
    title: PropTypes.string,
    path: PropTypes.string
  }),
  subHeading: PropTypes.string,
  modifier: PropTypes.string,
  equipment: PropTypes.array,
  icons: PropTypes.array,
  badges: PropTypes.array,
  elevated: PropTypes.bool
}

export const query = graphql`
  fragment WorkoutCard on DatoCmsWorkout {
    title
    slug
    id
    originalId
    duration
    defaultRating
    sweatometerCount
    sweatometerRating
    path
    showComments
    showSweatometer
    locale
    content {
      ... on DatoCmsEquipment {
        items {
          name
          model {
            apiKey
          }
          id
          icon {
            url
          }
        }
      }
    }
    category {
      title
    }
    media {
      ... on DatoCmsWorkoutVideoNative {
        video {
          url
        }
        model {
          apiKey
        }
        chosenThumbnail {
          gatsbyImageData(imgixParams: { fit: "crop", w: "740", h: "532" })
        }
      }
      ... on DatoCmsWorkoutVideo {
        id
        timestamp
        chosenThumbnail {
          gatsbyImageData(
            imgixParams: { fit: "crop", w: "740", h: "532", fm: "png" }
          )
        }
        model {
          apiKey
        }
      }
      ... on DatoCmsWorkoutAudio {
        id
        model {
          apiKey
        }
        caption
        banner {
          gatsbyImageData(imgixParams: { fit: "crop", w: "740", h: "532" })
        }
        model {
          apiKey
        }
      }
    }
    meta {
      publishedAt
    }
  }

  fragment RecipeCard on DatoCmsRecipe {
    id
    originalId
    slug
    title
    path
    prepTime
    cookTime
    serves
    heroImage {
      gatsbyImageData(imgixParams: { fit: "crop", w: "740", h: "532" })
    }
    mealTypes {
      id
      slug
      name
    }
    mainIngredient {
      id
      slug
      name
    }
    searchTerms {
      id
      slug
      name
    }
    dietaryTypes {
      id
      name
      badge {
        alt
        url
      }
    }

    ...RecipeBadges
  }

  fragment VlogCard on DatoCmsVlogPost {
    id
    originalId
    title
    path
    chosenThumbnail {
      gatsbyImageData(imgixParams: { fit: "crop", w: "740", h: "532" })
    }
    meta {
      publishedAt
    }
  }
  fragment EatGreatCard on DatoCmsEatGreat {
    id
    originalId
    title
    path
    chosenThumbnail {
      gatsbyImageData(imgixParams: { fit: "crop", w: "740", h: "532" })
    }
    meta {
      publishedAt
    }
  }
`
