import React from "react"
import { graphql } from "gatsby"
import style from "./badges.mod.scss"
import PropTypes from "prop-types"

const Badges = ({ badgeList = [{ svg: "", label: "" }], modifiers = [] }) => {
  return (
    <ul className={[style[`badges`], ...modifiers].join(" ")}>
      {badgeList.map(({ label, svg }) => (
        <li className={style.badges__item} key={`badge-${label}`}>
          <img className={style.badges__badge} src={svg} alt={label} />
        </li>
      ))}
    </ul>
  )
}

export default Badges

Badges.propTypes = {
  badgeList: PropTypes.array.isRequired,
  modifiers: PropTypes.array
}

export const query = graphql`
  fragment RecipeBadges on DatoCmsRecipe {
    dietaryTypes {
      name
      badge {
        alt
        url
      }
    }
  }
`
