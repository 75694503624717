import React from "react"
import { graphql } from "gatsby"
import Layout from "@layouts/layout"
import CardsContainer from "@global/cards/CardsContainer"
import Seo from "@elements/Seo"
import { transformVlog } from "@helpers/favouritesHelper"
import Block from "@global/layout/Block"
import Banner from "@global/layout/Banner"
import Card from "@global/cards/Card"
import Content from "@elements/Content"

const IDoEpisodes = ({ data, pageContext: { title, parent } }) => {
  const { nodes } = data.iDo
  const { iDo } = data.content
  const { iDoBanner } = data.banner

  return (
    <Layout parent={parent} title={title}>
      <Seo
        title={data.content.iDo.meta.title}
        description={data.content.iDo.meta.desc}
        image={iDoBanner.url}
      />
      <Banner image={iDoBanner} />
      <Block padding="double-top" gutters={true} maxWidth="inner">
        <Content html={iDo.content} />
      </Block>
      <Block gutters={true} padding="both" maxWidth="list-inner">
        {nodes.length > 0 ? (
          <CardsContainer>
            {nodes.map(node => {
              return (
                <Card
                  key={`i-do-card-${node.id}`}
                  {...transformVlog(node)}
                  modifier="horizontal-for-small"
                />
              )
            })}
          </CardsContainer>
        ) : (
          <Content textCenter={true} html={"<p>Episodes coming soon ...</p>"} />
        )}
      </Block>
    </Layout>
  )
}

export const query = graphql`
  query IDoQuery {
    iDo: allDatoCmsIDo(sort: { order: ASC, fields: position }) {
      nodes {
        title
        slug
        id
        path
        subHeading
        description
        chosenThumbnail {
          gatsbyImageData(imgixParams: { fit: "crop", w: "740", h: "532" })
        }
        meta {
          publishedAt
        }
      }
    }

    banner: datoCmsGlobal {
      iDoBanner {
        gatsbyImageData(
          imgixParams: { w: "1280", h: "640", fit: "crop", crop: "faces" }
        )
        url(imgixParams: { w: "1000", fit: "max", fm: "jpg" })
      }
    }

    content: contentYaml(language: { eq: "en-NZ" }) {
      iDo {
        content
        meta {
          title
          desc
        }
      }
    }
    seo: datoCmsSite {
      ...GlobalSeo
    }
  }
`

export default IDoEpisodes
